<template>
  <div class="subscribe-reply">
    <div class="title">
      自动回复以下消息的其中一条
    </div>
    <wx-auto-reply-editor v-if="!loading" :current-id="this.reply.wxAutoReply.id" :hidden-keyword="true" :hidden-title="true" :hidden-reply-method="true" :no-border="true" :hidden-content-label="true" />
  </div>
</template>

<script>
export default {
  name: "subscribe-reply",
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      reply: {}
    };
  },
  methods: {
    async loadReplyDetail() {
      try {
        this.loading = true;
        this.reply = await this.$http.doApi(this.apiKey);
      } catch (e) {
        console.log(e);
        let msg = e.msg ? e.msg : "发生错误，请稍后重试";
        this.$notify.error({
          title: "错误",
          message: msg,
          duration: 1500,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.$eventBus.$on("currentAppChanged", () => {
      this.loadReplyDetail();
    });
  },
  components: {
    "wx-auto-reply-editor": () => import("./WxAutoReplyEditor.vue"),
  },
};
</script>

<style lang="less">
.subscribe-reply {
  .title {
    font-weight: 400;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>